<div class="flex items-center justify-center mx-auto max-w-4xl">
  <form
    (submit)="$event.preventDefault()"
    (keyup.enter)="checkEnter()"
    (keyup.backspace)="checkBackspace()"
    (keyup.arrowup)="focusUpSuggestion()"
    (keyup.arrowdown)="focusDownSuggestion()"
    class="rounded-2xl border border-gray-700 p-2 flex items-center justify-between w-full relative"
  >
    <div class="relative flex items-center w-full" [formGroup]="searchForm">
      <div *ngIf="options.length > 0" class="flex items-center flex-nowrap gap-1 ml-1">
        <div *ngFor="let option of options" class="flex items-center gap-px">
          <span class="bg-gray-600 leading-none py-1 px-2 text-sm rounded-l whitespace-nowrap">{{
            option.option
          }}</span>
          <span class="bg-gray-600 leading-none py-1 px-2 text-sm whitespace-nowrap">{{ option.value }}</span>
          <a (click)="deleteOption(option)" class="bg-red-400/20 text-red-400 p-1 rounded-r cursor-pointer">
            <ui-icon [icon]="'x-mark'" class="w-3.5 h-3.5 block"></ui-icon>
          </a>
        </div>
      </div>

      <span
        class="bg-gray-600 leading-none py-1 px-2 text-sm rounded-l ml-1 whitespace-nowrap"
        *ngIf="currentSuggestion"
        >{{ currentSuggestion }}</span
      >
      <input
        #input
        class="w-full border-0 bg-transparent focus:outline-none focus:ring-0 placeholder:text-gray-600 p-0 pl-1 text-sm"
        [formControlName]="'input'"
        (focus)="isSuggestionListOpen = true"
      />
      <ul
        *ngIf="isSuggestionListOpen && !currentSuggestion && filteredSuggestions.length > 0"
        class="absolute top-full mt-5 border border-gray-700 bg-gray-900 p-2 flex flex-col gap-1 rounded-2xl w-96 -left-2"
      >
        <li role="presentation" *ngFor="let suggestions of filteredSuggestions; index as i">
          <a
            (click)="selectSuggestion(suggestions)"
            role="menuitem"
            tabindex="-1"
            class="hover:bg-gray-600 duration-0 leading-none py-2 px-3 rounded-lg w-full text-left flex items-center gap-2 cursor-pointer"
            [ngClass]="[i === suggestionFocused ? 'bg-gray-600' : 'bg-transparent']"
          >
            <ui-icon [icon]="suggestions.icon" class="w-4 h-4 block shrink-0"></ui-icon>
            {{ suggestions.value }}
          </a>
        </li>
      </ul>
    </div>
    <ui-button
      submit
      [size]="'xs'"
      [label]="'Suchen'"
      [color]="'blue'"
      [icon]="'magnifying-glass'"
      (cclick)="search()"
    ></ui-button>
  </form>
</div>

<div *ngIf="searchQuery">
  {{ searchQuery | json }}
</div>
