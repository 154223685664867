import { Component, Input } from '@angular/core';
import { Icon } from '@n/nui';

export interface Stat {
  label: string,
  currentNumber: number,
  compareNumber: number,
  isCurrency?: boolean,
}

@Component({
  selector: 'tx-portal-compare-stats',
  templateUrl: './compare-stats.component.html',
  styles: [':host { @apply w-full; }']
})
export class CompareStatsComponent {

  @Input() icon!: Icon;
  @Input() label!: string;
  @Input() count!: number;
  @Input() count30ago!: number;
  @Input() isCurrency?: boolean;

  getDiff(a: number, b:number): number {
    let res = (((a - b) / b) * 100)
    if(res > -5 && res < 5) {
      return Math.round(res * 100) / 100
    } else {
      return Math.round(res)
    }
  }
}
