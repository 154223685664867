/* eslint-disable brace-style */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const SCOPE = 'openid profile roles email offline_access';

export const authCodeFlowConfig: AuthConfig = {
  issuer: environment.oidc.issuer,
  redirectUri: window.location.origin + window.location.pathname,
  clientId: environment.oidc.clientId,
  postLogoutRedirectUri: window.location.origin + window.location.pathname,
  useIdTokenHintForSilentRefresh: true,
  //useSilentRefresh: true,
  responseType: 'code',
  scope: SCOPE,
  showDebugInformation: true,
  sessionChecksEnabled: true,
  clearHashAfterLogin: true,
  nonceStateSeparator: 'semicolon'
};

@Injectable({ providedIn: 'root' })
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);

  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);

  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private navigateToLoginPage() {
    // TODO: Remember current URL
    //this.router.navigateByUrl('/');
  }
  mapping: any = environment.adminRoleMapping;

  constructor(private oauthService: OAuthService, private router: Router) {
    this.oauthService.configure(authCodeFlowConfig);
    // Useful for debugging:
    this.oauthService.events.subscribe((event) => {
      if (event instanceof OAuthErrorEvent) {
        console.error('OAuthErrorEvent Object:', event);
      } else {
        //console.log('OAuthEvent Object:', event);
      }
    });

    this.oauthService.events.subscribe((_) => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });
    this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

    this.oauthService.events
      .pipe(filter((e) => ['token_received'].includes(e.type)))
      .subscribe((e) => this.oauthService.loadUserProfile());

    this.oauthService.events.pipe(filter((e) => ['token_expires', 'session_error'].includes(e.type))).subscribe((e) => {
      this.refresh();
    });

    //this.oauthService.setupAutomaticSilentRefresh();
  }

  public hasRole(role: string): boolean {
    console.log('checking role', role, this.identityClaims?.['groups']);
    return this.identityClaims?.['groups']?.includes(role);
  }
  public runInitialLoginSequence(): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocumentAndLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          this.redirectToFirstPage();
          return Promise.resolve();
        }
        return Promise.reject('Login Failed');
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  redirectToFirstPage() {
    if (this.identityClaims?.['groups']?.includes('ASG_AdminApp_KDC_Modul_Dashboard_R')) {
      return this.router.navigateByUrl('/dashboard');
    }
    if (this.identityClaims?.['groups']?.includes('ASG_AdminApp_KDC_Modul_DCAM_R')) {
      return this.router.navigateByUrl('/dcam');
    }
    return;
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(targetUrl || this.router.url);
  }

  public logout() {
    this.oauthService.logOut();
  }
  public refresh() {
    this.oauthService.refreshToken();
  }
  public hasValidToken() {
    return this.oauthService.hasValidAccessToken();
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() {
    return this.oauthService.getAccessToken();
  }
  public get refreshToken() {
    return this.oauthService.getRefreshToken();
  }
  public get identityClaims() {
    const claims = this.oauthService.getIdentityClaims();
    if (claims['groups']) {
      claims['groups'] = claims['groups'].map((groupId: string) => {
        if (this.mapping[groupId]) {
          return this.mapping[groupId];
        }
        return groupId;
      });
    }
    return claims;
  }
  public get idToken() {
    return this.oauthService.getIdToken();
  }
  public get logoutUrl() {
    return this.oauthService.logoutUrl;
  }
}
