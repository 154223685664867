import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@n/nui';
import { Stat } from '../compare-stats/compare-stats.component';

@Component({
  selector: 'tx-portal-sidebar-stats',
  templateUrl: './sidebar-stats.component.html',
})
export class SidebarStatsComponent implements OnInit {
  @Input() sum: number = 0;
  @Input() lastSum: number = 0;
  @Input() configs: number = 0;
  @Input() lastConfigs: number = 0;
  @Input() visitors: number = 0;
  @Input() lastVisitiors: number = 0;
  @Input() month: string = '';
  @Input() lastMonth: string = '';
  @Input() missingConfigs: number = 0;
  @Input() stats: Array<{ icon: Icon, label: string }> = [];

  compareStats: Array<Stat> = [];

  ngOnInit(): void {
    this.compareStats = [
      {
        label: "Umsatz",
        currentNumber: this.sum,
        compareNumber: this.lastSum,
        isCurrency: true, 
      },
      {
        label: "Konfigurationen",
        currentNumber: this.configs,
        compareNumber: this.lastConfigs,
        isCurrency: false, 
      },
      {
        label: "Besucher",
        currentNumber: this.visitors,
        compareNumber: this.lastVisitiors,
        isCurrency: false, 
      }
    ]
  }


  getDiff(a: number, b:number): number {
    return Math.round(((a - b) / b) * 100);
  }
}
