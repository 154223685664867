<div class="w-full relative group">
  <!-- link -->
  <a
    *ngIf="type === 'link'"
    [routerLink]="link"
    [routerLinkActive]="['text-white', 'bg-white/10']"
    [routerLinkActiveOptions]="{ exact: true }"
    class="w-full flex items-center justify-between font-medium text-gray-300 rounded-lg hover:bg-white/5"
    [ngClass]="!collapsed ? ' px-3 py-2' : 'p-2'"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </a>
  <ng-container *ngTemplateOutlet="hover"></ng-container>

  <!-- button & logout -->
  <button
    *ngIf="type === 'button' || type === 'logout'"
    class="w-full flex items-center justify-between font-medium text-gray-300 rounded-lg"
    [ngClass]="[
      !collapsed ? ' px-3 py-2' : 'p-2',
      type === 'button' ? 'hover:bg-white/5' : '',
      type === 'logout' ? 'hover:bg-red-500/10 hover:text-red-500' : ''
    ]"
    (click)="cclick.emit()"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </button>

  <!-- display & preview & lock -->
  <div
    *ngIf="type === 'preview' || type === 'display' || type === 'lock'"
    class="w-full flex items-center justify-between font-medium transition-all ease-in-out duration-200"
    [ngClass]="[
      !collapsed ? 'px-3 py-2' : 'p-2',
      type === 'preview' || type === 'lock' ? 'text-gray-400' : '',
      type === 'display' ? 'text-goblin-100' : ''
    ]"
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

<!-- templates -->
<ng-template #content>
  <span class="flex items-center gap-2">
    <ui-icon *ngIf="icon" [icon]="icon" class="block w-5 h-5 m-0.5"></ui-icon>
    <span *ngIf="!collapsed" class="whitespace-nowrap">{{ label }}</span>
  </span>
  <span
    class="font-normal text-sm whitespace-nowrap"
    *ngIf="!collapsed && count && type !== 'lock' && type !== 'preview'"
  >
    {{ count }}
  </span>
  <ui-icon *ngIf="!collapsed && type === 'lock'" [icon]="'lock-closed'" class="block w-5 h-5"></ui-icon>
  <ui-icon *ngIf="!collapsed && type === 'preview'" [icon]="'clock'" class="block w-5 h-5"></ui-icon>
</ng-template>

<ng-template #hover>
  <div
    *ngIf="collapsed"
    class="opacity-0 touch-none pointer-events-none group-hover:opacity-100 absolute left-[calc(100%+2rem)] top-0 flex items-center justify-between gap-4 font-medium transition-all ease-in-out duration-200 bg-gray-700 px-3 py-2 rounded-lg"
    [ngClass]="[
      type === 'preview' || type === 'lock' ? 'text-gray-400' : '',
      type === 'display' ? 'text-goblin-100' : '',
      type === 'logout' ? 'text-red-500' : ''
    ]"
  >
    <span class="flex items-center gap-2">
      <span class="whitespace-nowrap">{{ label }}</span>
    </span>
    <span class="font-normal text-sm whitespace-nowrap" *ngIf="count && type !== 'lock' && type !== 'preview'">
      {{ count }}
    </span>
    <ui-icon *ngIf="type === 'lock'" [icon]="'lock-closed'" class="block w-5 h-5"></ui-icon>
    <ui-icon *ngIf="type === 'preview'" [icon]="'clock'" class="block w-5 h-5"></ui-icon>
    <div
      class="absolute top-1/2 -translate-y-1/2 left-0 -translate-x-[8px] w-0 h-0 border-t-[6px] border-t-transparent border-r-[8px] border-r-gray-700 border-b-[6px] border-b-transparent"
    ></div>
  </div>
</ng-template>
