import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { Icon } from '@n/nui';

interface SearchPayload {
  options: Option[]
}

interface Option {
  option: string;
  value: string;
}

export interface Suggestion {
  icon: Icon,
  value: string,
}

@Component({
  selector: 'tx-portal-magic-search',
  templateUrl: './magic-search.component.html'
})
export class MagicSearchComponent implements OnInit {
  @ViewChild('input') input!: ElementRef;

  constructor(private fb: NonNullableFormBuilder) {}

  ngOnInit(): void {

    this.filterSuggestions();
    console.log('^ filter called from init func')
    this.searchForm.controls.input.valueChanges.subscribe(value => {
      this.backspacePressed = 0;
      if(value !== '') {
        this.suggestionFocused = 0;
      } else {
        this.suggestionFocused = -1;
      }
      this.filterSuggestions();
      console.log('^ filter called from form sub func')
    })
  }

  searchForm = this.fb.group({ input: '' });

  @Input() searchSuggestions: Suggestion[] = [{ icon: 'v-rename', value: 'Text' }]
  filteredSuggestions: Suggestion[] = [...this.searchSuggestions];
  isSuggestionListOpen = false;
  suggestionFocused = -1;

  currentSuggestion: string = '';

  options: Option[] = [];

  searchQuery: SearchPayload | null = null;

  // backspace functions
  backspacePressed = 0;
  checkBackspace() {
    if(this.searchForm.controls.input.value === '') {
      this.backspacePressed += 1;
      if(this.backspacePressed > 2) {
        if(this.currentSuggestion === '') {
          this.deleteOption(this.options[this.options.length - 1])
          this.backspacePressed = 0;
        } else {
          this.unselectSuggestion();
          this.backspacePressed = 0;
        }
      }
    }
  }


  checkEnter() {

    if(this.searchForm.controls.input.value === '' && this.currentSuggestion === '' && this.options.length > 0 && this.suggestionFocused === -1) {
      return this.search();
    }
    if(this.currentSuggestion === '' && this.suggestionFocused > -1 && this.filteredSuggestions.length > 0) {
      this.selectSuggestion(this.filteredSuggestions[this.suggestionFocused])
      this.searchForm.controls.input.setValue('');
    }
    if(this.currentSuggestion !== '' && this.searchForm.controls.input.value !== '') {
      this.finishOption({option: this.currentSuggestion, value: this.searchForm.controls.input.value});
    }
  }

  filterSuggestions() {
    // reset value
    this.filteredSuggestions = [...this.searchSuggestions];

    // remove suggestions if already in options
    this.filteredSuggestions = [...this.filteredSuggestions].filter(s => {
      return !this.options.some(o => {
        return o.option === s.value;
      })
    })

    // search for input
    if(this.searchForm.controls.input.value !== '') {
      this.filteredSuggestions = [...this.filteredSuggestions].filter(s => {
        return s.value.toLowerCase().includes(this.searchForm.controls.input.value.toLowerCase())
      });
    }

    this.filteredSuggestions.push({ icon: 'v-rename', value: 'Text' })
  }

  // focus up or down functions
  focusUpSuggestion() {
    if(this.filteredSuggestions.length < 1) return;
    if(this.suggestionFocused === 0 || this.suggestionFocused === -1) {
      this.suggestionFocused = this.filteredSuggestions.length-1;
    } else {
      this.suggestionFocused = this.suggestionFocused-1;
    }
  }
  focusDownSuggestion() {
    if(this.filteredSuggestions.length < 1) return;
    if(this.suggestionFocused === this.filteredSuggestions.length-1) {
      this.suggestionFocused = 0;
    } else {
      this.suggestionFocused = this.suggestionFocused+1;
    }
  }

  // suggestion functions
  selectSuggestion(suggestion: Suggestion) {
    console.log('suggestion ' + suggestion.value + ' will be selected, input will be focused again and suggestion focused will be set to -1');
    this.currentSuggestion = suggestion.value;
    this.input.nativeElement.focus();
    this.suggestionFocused = -1;
  }
  unselectSuggestion() {
    this.searchForm.controls.input.setValue(this.currentSuggestion)
    this.currentSuggestion = '';
  }

  // options functions
  finishOption(option: Option) {
    this.options.push(option)
    this.searchForm.controls.input.setValue('');
    this.currentSuggestion = '';
  }
  deleteOption(option: Option) {
    this.options = this.options.filter(o => {
      return o !== option;
    })
    this.input.nativeElement.focus()
    this.filterSuggestions();
    console.log('^ filter called from deleteOption func')
  }

  // search functions
  search() {
    this.isSuggestionListOpen = false;
    this.searchQuery = {
      options: this.options
    };
  }
}
