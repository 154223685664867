import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tx-portal-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(public authService: AuthService, private router: Router) {}

  collapsed: boolean = false;

  logout() {
    this.authService.logout();
  }

  navigateToEasteregg() {
    this.router.navigate(['/easteregg']);
  }
}
