<div class="relative" #elem>
  <ui-button
    class="w-full sm:w-auto whitespace-nowrap"
    [color]="'white'"
    [size]="'sm'"
    [icon]="'view-columns'"
    (cclick)="isMenuOpen = !isMenuOpen"
    aria-expanded="true"
    aria-haspopup="true"
    >Tabelle anpassen</ui-button
  >
  <!-- menu -->
  <div
    *ngIf="isMenuOpen"
    class="absolute right-0 top-0 z-10 w-64 py-1 mt-2 origin-top-right bg-white shadow-lg rounded-xl translate-y-10"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
  >
    <div class="flex items-center justify-between px-4 py-3" role="none">
      <span class="text-sm font-medium">Dargestellte Spalten</span>
    </div>
    <hr />
    <div class="py-3" role="none">
      <div class="w-full px-4 py-1" *ngFor="let col of cols">
        <ui-forms-checkbox
          [id]="col.id"
          [label]="col.label"
          [parentForm]="columnForm"
          [controlName]="col.id"
        ></ui-forms-checkbox>
      </div>
      <div class="w-full px-4 pt-1">
        <ui-button [size]="'xs'" [color]="'red'" [icon]="'arrow-path'" [type]="'secondary'" (cclick)="resetForm.emit()"
          >Zurücksetzen</ui-button
        >
      </div>
    </div>
  </div>
</div>
