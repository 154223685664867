import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ui-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent {
  @Input()
  title = '';

  @Input()
  content = '';

  @Input()
  isModalOpen!: boolean;

  @Input()
  component: any;

  @Input()
  label = 'Absenden';

  @Input()
  isCloseButtonVisible: boolean = true;

  animate = false;

  @Output()
  onClose = new EventEmitter<Event>();

  @Output()
  onSubmit = new EventEmitter<Event>();

  constructor(private cdr: ChangeDetectorRef) {}

  close() {
    this.animate = false;
    this.cdr.detectChanges();

    setTimeout(() => {
      this.onClose.emit();
    }, 300);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.animate = true;
      this.cdr.detectChanges();
    }, 0);
  }
}
