import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarStatsComponent } from './sidebar-stats/sidebar-stats.component';
import { NavItemComponent } from './nav-item/nav-item.component';
import { NuiModule } from '@n/nui';
import { RouterModule } from '@angular/router';
import { CompareStatsComponent } from './compare-stats/compare-stats.component';
import { SingleStatComponent } from './single-stat/single-stat.component';
import { MagicSearchComponent } from './magic-search/magic-search.component';
import { UiModule } from '@tx/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    SidebarStatsComponent,
    NavItemComponent,
    CompareStatsComponent,
    SingleStatComponent,
    MagicSearchComponent
  ],
  imports: [CommonModule, NuiModule, RouterModule, UiModule, ReactiveFormsModule, MatFormFieldModule],
  exports: [SidebarStatsComponent, NavItemComponent, CompareStatsComponent, SingleStatComponent, MagicSearchComponent]
})
export class SharedModule {}
