import { Component, Input, OnInit } from '@angular/core';
import { Icon } from '@n/nui';

@Component({
  selector: 'tx-portal-single-stat',
  templateUrl: './single-stat.component.html'
})
export class SingleStatComponent implements OnInit {
  constructor() {}

  @Input() icon!: Icon;
  @Input() count!: number;
  @Input() label!: string;

  ngOnInit(): void {}
}
