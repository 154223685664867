<div class="w-[calc(100%-32rem)] p-8">
  <ng-content></ng-content>
</div>
<div
  class="fixed right-0 inset-y-0 w-[32rem] bg-gray-950 p-8 flex flex-col items-center justify-start gap-2 overflow-y-scroll"
>
  <span class="text-center text-xl font-semibold">Übersicht der letzten 30 Tage</span>

  <span class="mt-4 leading-none">Gesamtumsatz</span>
  <span class="font-bold text-lg text-klee-80">{{ sum | currency : 'EUR' }}*</span>
  <span class="text-gray-500 text-xs" *ngIf="missingConfigs"
    >Davon <span class="text-purple-400">{{ missingConfigs }}</span> ausgeschlossen aufgrund von "Preis auf
    Anfrage"</span
  >

  <div class="flex gap-8 mt-4 w-full px-8 mb-8">
    <div class="flex flex-col items-center gap-2 w-1/2">
      <span class="text-sm">Konfigurationen</span>
      <span class="text-lg font-medium">{{ configs | number }}</span>
    </div>
    <div class="h-full w-px bg-gray-800"></div>
    <div class="flex flex-col items-center gap-2 w-1/2">
      <span class="text-sm">Besucher</span>
      <span class="text-lg font-medium">{{ 106906 | number }}</span>
    </div>
  </div>

  <!-- <tx-portal-compare-stats
    headline="Im Vergleich zum letzten Monat"
    currentMonth="September"
    compareMonth="August"
    [stats]="compareStats"
  ></tx-portal-compare-stats> -->

  <!-- <div class="rounded-lg w-full p-8 border border-gray-800 mt-8 flex flex-col">
    <span class="font-semibold">Im Vergleich zum letzten Monat </span>
    <span class="text-gray-500">{{ lastMonth }} ↔ {{ month }}</span>
    <span class="mt-4 text-gray-500"> Umsatz </span>
    <div class="font-medium inline-flex gap-1">
      <span class="text-purple-400">{{ lastSum | currency : 'EUR' }}*</span>
      ↔
      {{ sum | currency : 'EUR' }}*
      <span class="flex gap-0.5 items-center ml-2" [ngClass]="sum > lastSum ? 'text-klee-80' : 'text-red-400'">
        <ui-icon [icon]="sum > lastSum ? 'arrow-up' : 'arrow-down'" class="w-4 h-4 block"></ui-icon>
        ({{ getDiff(sum, lastSum) }}%)
      </span>
    </div>
    <span class="mt-2 text-gray-500"> Konfigurationen </span>
    <div class="font-medium inline-flex gap-1">
      <span class="text-purple-400">{{ lastConfigs | number }}</span>
      ↔
      {{ configs | number }}
      <span class="flex gap-0.5 items-center ml-2" [ngClass]="configs > lastConfigs ? 'text-klee-80' : 'text-red-400'">
        <ui-icon [icon]="configs > lastConfigs ? 'arrow-up' : 'arrow-down'" class="w-4 h-4 block"></ui-icon>
        ({{ getDiff(configs, lastConfigs) }}%)
      </span>
    </div>
    <span class="mt-2 text-gray-500"> Besucher </span>
    <div class="font-medium inline-flex gap-1">
      <span class="text-purple-400">{{ lastVisitiors | number }}</span>
      ↔
      {{ visitiors | number }}
      <span
        class="flex gap-0.5 items-center ml-2"
        [ngClass]="visitiors > lastVisitiors ? 'text-klee-80' : 'text-red-400'"
      >
        <ui-icon [icon]="visitiors > lastVisitiors ? 'arrow-up' : 'arrow-down'" class="w-4 h-4 block"></ui-icon>
        ({{ getDiff(visitiors, lastVisitiors) > 0 ? '+' : '' }}{{ getDiff(visitiors, lastVisitiors) }}%)
      </span>
    </div>
  </div> -->

  <span class="mb-8 mt-4 text-xs text-gray-500 w-full text-center"
    >* Hier handelt es sich um konfigurierte Beträge und keine generierten Umsätze.</span
  >

  <span class="w-full text-left font-semibold text-gray-400">Übersicht die gesamte Zeit</span>
  <div class="w-full flex flex-col items-start text-gray-500 gap-4 mt-2">
    <div *ngFor="let stat of stats" class="flex gap-2 items-center">
      <div class="rounded-full border border-gray-800 w-10 h-10 flex items-center justify-center">
        <ui-icon [icon]="stat.icon" class="block w-4 h-4"></ui-icon>
      </div>
      <span>{{ stat.label }}</span>
    </div>
  </div>
</div>
