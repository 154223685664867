import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Icon } from '@n/nui';

@Component({
  selector: 'tx-portal-nav-item',
  templateUrl: './nav-item.component.html'
})
export class NavItemComponent {
  // types
  @Input() type: 'link' | 'button' | 'logout' | 'preview' | 'display' | 'lock' = 'link'

  // for all types
  @Input() icon: Icon | null = null;
  @Input() label: string = "";
  @Input() collapsed: boolean = false;

  // only for type link
  @Input() link: string = "";
  @Input() count: number | null = null;

  @Output() cclick: EventEmitter<null> = new EventEmitter<null>();

}
