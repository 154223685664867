<!-- This example requires Tailwind CSS v2.0+ -->
<div
  class="fixed inset-0 z-50 overflow-x-hidden overflow-y-scroll"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
  *ngIf="isModalOpen"
>
  <div class="flex items-center justify-center p-4 sm:p-12 text-center min-h-screen">
    <!--
        Background overlay, show/hide based on modal state.
  
        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
    <div
      *ngIf="isCloseButtonVisible"
      class="fixed inset-0 transition-opacity duration-300 ease-out bg-black bg-opacity-50 opacity-0"
      [ngClass]="{ 'opacity-100': animate }"
      aria-hidden="true"
      (click)="close()"
    ></div>
    <div
      *ngIf="!isCloseButtonVisible"
      class="fixed inset-0 transition-opacity duration-300 ease-out bg-black bg-opacity-50 opacity-0"
      [ngClass]="{ 'opacity-100': animate }"
      aria-hidden="true"
    ></div>

    <div
      class="inline-flex flex-col items-center justify-start text-left align-bottom transition-all duration-300 ease-out transform bg-white opacity-0 sm:rounded-3xl rounded-2xl sm:align-middle sm:max-w-4xl w-full translate-y-0 sm:scale-95"
      [ngClass]="{ 'opacity-100 translate-y-0 sm:scale-100': animate }"
    >
      <div class="absolute top-0 right-0 z-10 hidden pt-8 pr-8 sm:block" *ngIf="isCloseButtonVisible">
        <ui-button [icon]="'x-mark'" [size]="'sm'" [color]="'gray'" (cclick)="close()" [isOnlyIcon]="true"></ui-button>
      </div>
      <div class="relative w-full p-4 sm:p-8">
        <ng-content></ng-content>
      </div>
      <div class="flex items-center justify-end w-full p-8 pt-0" *ngIf="label">
        <ui-button [label]="label" [type]="'primary'" [size]="'sm'" (click)="onSubmit.emit($event)"> </ui-button>
      </div>
    </div>
  </div>
</div>
