import { InjectionToken } from '@angular/core';
export const APP_CONFIG_TOKEN = new InjectionToken<any>('config');
export const environment = {
  production: true,
  apiBasePath: 'https://my-stage.telemaxx.de/api',
  graphqlEndpoint: 'https://admin-stage.kdc.telemaxx.io/api/graphql',
  graphqlEndpointWs: 'wss:///my-stage.telemaxx.de/api/graphql',
  oidc: {
    clientId: 'tx-admin',
    issuer: 'https://auth-stage.telemaxx.de/realms/telemaxx'
  },
  adminRoleMapping: {
    'ed543366-92e8-4338-b684-1201a7f73706': 'ASG_AdminApp_Access_Allowed',
    '3b7a5953-7d6f-4238-af9c-96578dd62f38': 'ASG_AdminApp_Calcify_R',
    'fa818ad4-5ded-4498-95a1-3f27186e6bdf': 'ASG_AdminApp_Rackify_R',
    '0116d0e7-5499-4878-a49f-347e7bd100b6': 'ASG_AdminApp_KDC_Modul_Accounts_R',
    '121a03a1-3b21-46a4-8b57-64cd2535c448': 'ASG_AdminApp_KDC_Modul_Company_R',
    '20da9855-b170-41fe-afc0-edabd71e5453': 'ASG_AdminApp_KDC_Modul_Dashboard_R',
    '15da4e33-aa89-4e44-b1ba-3083b78efb22': 'ASG_AdminApp_KDC_Modul_DCAM_R',
    'aa8fdb3b-ada1-45c3-ad3b-7d1ecd2c4f7a': 'ASG_AdminApp_KDC_Modul_Invoices_R',
    '53145a33-958c-4faf-923c-5180b2d9c1e5': 'ASG_AdminApp_KDC_Modul_Services_R'
  }
};
