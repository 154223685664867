import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import * as ApolloCore from '@apollo/client/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
};

export type Dcam = {
  __typename?: 'Dcam';
  access: Array<DcamAccess>;
  companyId: Scalars['ID']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};


export type DcamAccessArgs = {
  filter?: DcamAccessFilter;
  sorting?: Array<DcamAccessSort>;
};

export type DcamAccess = {
  __typename?: 'DcamAccess';
  accessType: DcamAccessMode;
  contacts?: Maybe<Array<DcamContact>>;
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  keySet?: Maybe<DcamKeyset>;
  name?: Maybe<Scalars['String']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};


export type DcamAccessContactsArgs = {
  filter?: DcamContactFilter;
  sorting?: Array<DcamContactSort>;
};

export type DcamAccessFilter = {
  and?: InputMaybe<Array<DcamAccessFilter>>;
  contacts?: InputMaybe<DcamAccessFilterDcamContactFilter>;
  id?: InputMaybe<IdFilterComparison>;
  keySet?: InputMaybe<DcamAccessFilterDcamKeysetFilter>;
  name?: InputMaybe<StringFieldComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamAccessFilter>>;
};

export type DcamAccessFilterDcamContactFilter = {
  and?: InputMaybe<Array<DcamAccessFilterDcamContactFilter>>;
  birthday?: InputMaybe<StringFieldComparison>;
  company?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamAccessFilterDcamContactFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type DcamAccessFilterDcamKeysetFilter = {
  and?: InputMaybe<Array<DcamAccessFilterDcamKeysetFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  keyId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamAccessFilterDcamKeysetFilter>>;
};

export type DcamAccessInput = {
  accessType: DcamAccessMode;
  created: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export enum DcamAccessMode {
  Read = 'READ',
  Write = 'WRITE'
}

export type DcamAccessSort = {
  direction: SortDirection;
  field: DcamAccessSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DcamAccessSortFields {
  Id = 'id',
  Name = 'name',
  Note = 'note'
}

export type DcamContact = {
  __typename?: 'DcamContact';
  birthday: Scalars['String']['output'];
  changedBy: Scalars['String']['output'];
  company: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender: Gender;
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  note?: Maybe<Scalars['String']['output']>;
  phone: Scalars['String']['output'];
  updated: Scalars['DateTime']['output'];
  version: Scalars['Float']['output'];
};

export type DcamContactFilter = {
  and?: InputMaybe<Array<DcamContactFilter>>;
  birthday?: InputMaybe<StringFieldComparison>;
  company?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamContactFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type DcamContactSort = {
  direction: SortDirection;
  field: DcamContactSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DcamContactSortFields {
  Birthday = 'birthday',
  Company = 'company',
  Email = 'email',
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName',
  Note = 'note',
  Phone = 'phone'
}

export type DcamFilter = {
  access?: InputMaybe<DcamFilterDcamAccessFilter>;
  and?: InputMaybe<Array<DcamFilter>>;
  companyId?: InputMaybe<IdFilterComparison>;
  id?: InputMaybe<IdFilterComparison>;
  or?: InputMaybe<Array<DcamFilter>>;
};

export type DcamFilterDcamAccessFilter = {
  and?: InputMaybe<Array<DcamFilterDcamAccessFilter>>;
  contacts?: InputMaybe<DcamFilterDcamAccessFilterDcamContactFilter>;
  id?: InputMaybe<IdFilterComparison>;
  keySet?: InputMaybe<DcamFilterDcamAccessFilterDcamKeysetFilter>;
  name?: InputMaybe<StringFieldComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamFilterDcamAccessFilter>>;
};

export type DcamFilterDcamAccessFilterDcamContactFilter = {
  and?: InputMaybe<Array<DcamFilterDcamAccessFilterDcamContactFilter>>;
  birthday?: InputMaybe<StringFieldComparison>;
  company?: InputMaybe<StringFieldComparison>;
  email?: InputMaybe<StringFieldComparison>;
  firstName?: InputMaybe<StringFieldComparison>;
  id?: InputMaybe<IdFilterComparison>;
  lastName?: InputMaybe<StringFieldComparison>;
  note?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamFilterDcamAccessFilterDcamContactFilter>>;
  phone?: InputMaybe<StringFieldComparison>;
};

export type DcamFilterDcamAccessFilterDcamKeysetFilter = {
  and?: InputMaybe<Array<DcamFilterDcamAccessFilterDcamKeysetFilter>>;
  id?: InputMaybe<IdFilterComparison>;
  keyId?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<DcamFilterDcamAccessFilterDcamKeysetFilter>>;
};

export type DcamInput = {
  companyId: Scalars['ID']['input'];
  created: Scalars['DateTime']['input'];
  id: Scalars['ID']['input'];
  updated: Scalars['DateTime']['input'];
  version: Scalars['Float']['input'];
};

export type DcamKeyset = {
  __typename?: 'DcamKeyset';
  id: Scalars['ID']['output'];
  keyId?: Maybe<Scalars['String']['output']>;
  serviceInfos?: Maybe<Array<DcamKeysetServiceInfo>>;
};

export type DcamKeysetInput = {
  id: Scalars['ID']['input'];
  keyId?: InputMaybe<Scalars['String']['input']>;
  serviceInfos?: InputMaybe<Array<DcamKeysetServiceInfoInput>>;
};

export type DcamKeysetServiceInfo = {
  __typename?: 'DcamKeysetServiceInfo';
  dc: Scalars['String']['output'];
  loc1: Scalars['String']['output'];
  loc2: Scalars['String']['output'];
  loc3: Scalars['String']['output'];
  loc4?: Maybe<Scalars['String']['output']>;
  loc5?: Maybe<Scalars['String']['output']>;
  serviceNumber: Scalars['String']['output'];
};

export type DcamKeysetServiceInfoInput = {
  dc: Scalars['String']['input'];
  loc1: Scalars['String']['input'];
  loc2: Scalars['String']['input'];
  loc3: Scalars['String']['input'];
  loc4?: InputMaybe<Scalars['String']['input']>;
  loc5?: InputMaybe<Scalars['String']['input']>;
  serviceNumber: Scalars['String']['input'];
};

export type DcamSort = {
  direction: SortDirection;
  field: DcamSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DcamSortFields {
  CompanyId = 'companyId',
  Id = 'id'
}

/** Gender of a Person */
export enum Gender {
  D = 'd',
  M = 'm',
  W = 'w'
}

export type IdFilterComparison = {
  eq?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  iLike?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<Scalars['ID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['ID']['input']>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  neq?: InputMaybe<Scalars['ID']['input']>;
  notILike?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<Scalars['ID']['input']>>;
  notLike?: InputMaybe<Scalars['ID']['input']>;
};

export type Query = {
  __typename?: 'Query';
  dcam: Dcam;
  dcamAccess: DcamAccess;
  dcamAccesses: Array<DcamAccess>;
  dcams: Array<Dcam>;
};


export type QueryDcamArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDcamAccessArgs = {
  id: Scalars['ID']['input'];
};


export type QueryDcamAccessesArgs = {
  filter?: DcamAccessFilter;
  sorting?: Array<DcamAccessSort>;
};


export type QueryDcamsArgs = {
  filter?: DcamFilter;
  sorting?: Array<DcamSort>;
};

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DcamContactFragment = { __typename?: 'DcamContact', id: string, firstName: string, lastName: string, phone: string, email: string, company: string, note?: string | null, birthday: string, gender: Gender, changedBy: string, updated: any, created: any, version: number };

export type FindByContactQueryVariables = Exact<{
  filter: Array<DcamAccessFilterDcamContactFilter> | DcamAccessFilterDcamContactFilter;
  filter2: Array<DcamContactFilter> | DcamContactFilter;
}>;


export type FindByContactQuery = { __typename?: 'Query', dcamAccesses: Array<{ __typename?: 'DcamAccess', id: string, contacts?: Array<{ __typename?: 'DcamContact', id: string, firstName: string, lastName: string, phone: string, email: string, company: string, note?: string | null, birthday: string, gender: Gender, changedBy: string, updated: any, created: any, version: number }> | null, keySet?: { __typename?: 'DcamKeyset', id: string, keyId?: string | null, serviceInfos?: Array<{ __typename?: 'DcamKeysetServiceInfo', dc: string, loc1: string, loc2: string, loc3: string, loc4?: string | null, loc5?: string | null, serviceNumber: string }> | null } | null }> };

export type FindByKeyIdQueryVariables = Exact<{
  keyId: Scalars['String']['input'];
}>;


export type FindByKeyIdQuery = { __typename?: 'Query', dcamAccesses: Array<{ __typename?: 'DcamAccess', id: string, contacts?: Array<{ __typename?: 'DcamContact', id: string, firstName: string, lastName: string, phone: string, email: string, company: string, note?: string | null, birthday: string, gender: Gender, changedBy: string, updated: any, created: any, version: number }> | null, keySet?: { __typename?: 'DcamKeyset', id: string, keyId?: string | null, serviceInfos?: Array<{ __typename?: 'DcamKeysetServiceInfo', dc: string, loc1: string, loc2: string, loc3: string, loc4?: string | null, loc5?: string | null, serviceNumber: string }> | null } | null }> };

export const DcamContactFragmentDoc = gql`
    fragment DcamContact on DcamContact {
  id
  firstName
  lastName
  phone
  email
  company
  note
  birthday
  gender
  changedBy
  updated
  created
  version
}
    `;
export const FindByContactDocument = gql`
    query findByContact($filter: [DcamAccessFilterDcamContactFilter!]!, $filter2: [DcamContactFilter!]!) {
  dcamAccesses(filter: {contacts: {and: $filter}}) {
    id
    contacts(filter: {and: $filter2}) {
      ...DcamContact
    }
    keySet {
      id
      keyId
      serviceInfos {
        dc
        loc1
        loc2
        loc3
        loc4
        loc5
        serviceNumber
      }
    }
  }
}
    ${DcamContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindByContactGQL extends Apollo.Query<FindByContactQuery, FindByContactQueryVariables> {
    override document = FindByContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FindByKeyIdDocument = gql`
    query findByKeyId($keyId: String!) {
  dcamAccesses(filter: {keySet: {keyId: {eq: $keyId}}}) {
    id
    contacts {
      ...DcamContact
    }
    keySet {
      id
      keyId
      serviceInfos {
        dc
        loc1
        loc2
        loc3
        loc4
        loc5
        serviceNumber
      }
    }
  }
}
    ${DcamContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FindByKeyIdGQL extends Apollo.Query<FindByKeyIdQuery, FindByKeyIdQueryVariables> {
    override document = FindByKeyIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

  type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

  interface WatchQueryOptionsAlone<V> extends Omit<ApolloCore.WatchQueryOptions<V>, 'query' | 'variables'> {}

  interface QueryOptionsAlone<V> extends Omit<ApolloCore.QueryOptions<V>, 'query' | 'variables'> {}

  @Injectable({ providedIn: 'root' })
  export class TxAdminApi {
    constructor(
      private findByContactGql: FindByContactGQL,
      private findByKeyIdGql: FindByKeyIdGQL
    ) {}
      
    findByContact(variables: FindByContactQueryVariables, options?: QueryOptionsAlone<FindByContactQueryVariables>) {
      return this.findByContactGql.fetch(variables, options)
    }
    
    findByContactWatch(variables: FindByContactQueryVariables, options?: WatchQueryOptionsAlone<FindByContactQueryVariables>) {
      return this.findByContactGql.watch(variables, options)
    }
    
    findByKeyId(variables: FindByKeyIdQueryVariables, options?: QueryOptionsAlone<FindByKeyIdQueryVariables>) {
      return this.findByKeyIdGql.fetch(variables, options)
    }
    
    findByKeyIdWatch(variables: FindByKeyIdQueryVariables, options?: WatchQueryOptionsAlone<FindByKeyIdQueryVariables>) {
      return this.findByKeyIdGql.watch(variables, options)
    }
  }