<div class="rounded-xl w-full p-8 border border-gray-700 flex flex-col">
  <div class="flex gap-2 items-center">
    <ui-icon [icon]="icon" class="shrink-0 w-5 h-5"></ui-icon>
    <span class="font-semibold text-xl">{{ label }}</span>
  </div>
  <div class="font-semibold text-3xl mt-8 mb-2">
    {{ count | number }}
  </div>
  <div class="flex gap-2 items-center">
    <span
      class="flex gap-0.5 items-center px-2 py-1 leading-tight font-semibold rounded-md text-sm"
      [ngClass]="
        count == count30ago
          ? 'text-gray-500 bg-gray-500/20'
          : count > count30ago
          ? 'text-klee-80 bg-klee-80/20'
          : 'text-red-400 bg-red-400/20'
      "
    >
      {{ count > count30ago ? '+' : '' }}{{ getDiff(count, count30ago) }}%
    </span>
    <span class="text-gray-500">↔ letzten 30 Tagen</span>
  </div>
</div>
